const EXPLOITATION_OPTIONS_CONFIGURATION_SCHEMA = {
  'title': 'Exploiters Options',
  'type': 'object',
  'properties': {
    'http_ports': {
      'title': 'HTTP Ports',
      'type': 'array',
      'items': {
        'type': 'integer',
        'minimum': 0,
        'maximum': 65535
      },
      'default': [80, 8080, 443, 8008, 7001, 8983, 9600],
      'description': 'List of HTTP ports the monkey agent will try to exploit'
    }
  }
}
export default EXPLOITATION_OPTIONS_CONFIGURATION_SCHEMA;
