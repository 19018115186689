import EXPLOITATION_OPTIONS_CONFIGURATION_SCHEMA from '../exploitationOptions.js';


const EXPLOITATION_CONFIGURATION_SCHEMA = {
  'title': 'Propagation',
  'properties': {
    'exploiters': {
      // Exploiters get injected from schema generated on the back end
       },

    'options': EXPLOITATION_OPTIONS_CONFIGURATION_SCHEMA
  },
  'type': 'object'
};

export default EXPLOITATION_CONFIGURATION_SCHEMA;
