// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#C1txF0MBSavovwUM5DC1 .grid-tools .actions{display:flex;justify-content:flex-end}", "",{"version":3,"sources":["webpack://./src/styles/components/plugins-marketplace/AvailablePlugins.module.scss"],"names":[],"mappings":"AAGM,2CACI,YAAA,CACA,wBAAA","sourcesContent":["#available-plugins {\n  :global {\n    .grid-tools {\n      .actions {\n          display: flex;\n          justify-content: flex-end;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"available-plugins": "C1txF0MBSavovwUM5DC1"
};
export default ___CSS_LOADER_EXPORT___;
