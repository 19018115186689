import {createTheme} from '@mui/material';

const MUITheme= createTheme({
  palette: {
    primary: {
      main: '#ffcc00'
    }
  }
});

export default MUITheme;
