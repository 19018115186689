// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BEIfAdrmsHBw7PjexKVj .MuiDataGrid-columnHeader,.BEIfAdrmsHBw7PjexKVj .MuiDataGrid-cell{width:20% !important}.BEIfAdrmsHBw7PjexKVj .s-col--header,.BEIfAdrmsHBw7PjexKVj .s-col{width:10% !important}.BEIfAdrmsHBw7PjexKVj .m-col--header,.BEIfAdrmsHBw7PjexKVj .m-col{width:15% !important}.BEIfAdrmsHBw7PjexKVj .xl-col--header,.BEIfAdrmsHBw7PjexKVj .xl-col{width:40% !important}.BEIfAdrmsHBw7PjexKVj .row-actions--header,.BEIfAdrmsHBw7PjexKVj .row-actions{width:10% !important}", "",{"version":3,"sources":["webpack://./src/styles/components/plugins-marketplace/PluginTable.module.scss"],"names":[],"mappings":"AAEI,wFACE,oBAAA,CAGF,kEACE,oBAAA,CAGF,kEACE,oBAAA,CAGF,oEACE,oBAAA,CAGF,8EACE,oBAAA","sourcesContent":[".plugins-wrapper {\r\n  :global {\r\n    .MuiDataGrid-columnHeader, .MuiDataGrid-cell {\r\n      width: 20% !important;\r\n    }\r\n\r\n    .s-col--header, .s-col {\r\n      width: 10% !important;\r\n    }\r\n\r\n    .m-col--header, .m-col {\r\n      width: 15% !important;\r\n    }\r\n\r\n    .xl-col--header, .xl-col {\r\n      width: 40% !important;\r\n    }\r\n\r\n    .row-actions--header, .row-actions {\r\n      width: 10% !important;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"plugins-wrapper": "BEIfAdrmsHBw7PjexKVj"
};
export default ___CSS_LOADER_EXPORT___;
