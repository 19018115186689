// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#fpuLq_NWAW0CPpRO6HJm #drop-zone{flex:1;display:flex;flex-direction:column;align-items:center;padding:40px;border-width:2px;border-radius:10px;border-style:dashed;background-color:#fafafa;cursor:pointer;color:#000;font-weight:bold;font-size:1.4rem;outline:none;transition:border .24s ease-in-out}#fpuLq_NWAW0CPpRO6HJm #drop-zone:hover{border-color:#ffc107 !important}#fpuLq_NWAW0CPpRO6HJm #circle-list li{list-style-type:circle;overflow:visible !important}", "",{"version":3,"sources":["webpack://./src/styles/components/plugins-marketplace/UploadNewPlugin.module.scss"],"names":[],"mappings":"AAEI,iCACE,MAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,YAAA,CACA,gBAAA,CACA,kBAAA,CACA,mBAAA,CACA,wBAAA,CACA,cAAA,CACA,UAAA,CACA,gBAAA,CACA,gBAAA,CACA,YAAA,CACA,kCAAA,CAEF,uCACE,+BAAA,CAEF,sCACE,sBAAA,CACA,2BAAA","sourcesContent":["#upload-new-plugin{\n  :global {\n    #drop-zone{\n      flex: 1;\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n      padding: 40px;\n      border-width: 2px;\n      border-radius: 10px;\n      border-style: dashed;\n      background-color: #fafafa;\n      cursor: pointer;\n      color: black;\n      font-weight: bold;\n      font-size: 1.4rem;\n      outline: none;\n      transition: border 0.24s ease-in-out;\n    }\n    #drop-zone:hover {\n      border-color: #ffc107 !important;\n    }\n    #circle-list li{\n      list-style-type: circle;\n      overflow: visible !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upload-new-plugin": "fpuLq_NWAW0CPpRO6HJm"
};
export default ___CSS_LOADER_EXPORT___;
