// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#BvDQXR621SdSBi_wtsiN #installed-plugins-tab-label{margin-right:16px}", "",{"version":3,"sources":["webpack://./src/styles/pages/Marketplace.module.scss"],"names":[],"mappings":"AAEI,mDACE,iBAAA","sourcesContent":["#marketplace-page {\n  :global {\n    #installed-plugins-tab-label {\n      margin-right: 16px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"marketplace-page": "BvDQXR621SdSBi_wtsiN"
};
export default ___CSS_LOADER_EXPORT___;
